import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_single_image()
})

function dhsv_vc_single_image() {
  $('.dhsv_vc_single_image').each(function() {})
  /*
  // Bezieht die Dimensionen für die Berechnung der Parallaxen Verschiebung einmalig
  bezieheDimensionen()

  // Schaut wann gescrollt und führt die Funktion zur parallaxen Verschiebung aus
  $(window).scroll(function() {
    parallaxeVerschiebung()
  })

  // Die Verschiebung der Hintergrundebene entspricht der gescrollten Hoehe geteilt durch diesen Wert.	// bewegungsTeiler = 1.1= 2
  var fensterHoehe = 0,
    bewegungsTeiler = 1.3

  function bezieheDimensionen() {
    fensterHoehe = $(window).height()
    $('.vc_section').css('height', fensterHoehe)
  }

  function parallaxeVerschiebung() {
    // Prueft die aktuelle Scrollposition
    var gescrollt =
        (document.documentElement && document.documentElement.scrollTop) ||
        document.body.scrollTop,
      // Berechnet die gegenwaertige Sektion
      gegenwaertigeSektion = Math.ceil(gescrollt / fensterHoehe),
      // Berechnet den scrollabstand zur naechsten Sektion
      gescrolltInSektion =
        gescrollt - Math.ceil(fensterHoehe * gegenwaertigeSektion),
      // Berechnet Position des Blocks mit der Klasse "hintergrundbild" fuer die gegenwaertige Sektion
      bewegungAktiv = Math.round(
        gescrolltInSektion / bewegungsTeiler + fensterHoehe / bewegungsTeiler
      ),
      // Berechnet Position des Blocks mit der Klasse "hintergrundbild" fuer die kommende Sektion
      bewegungProaktiv = Math.round(gescrolltInSektion / bewegungsTeiler)

    // Prueft in welcher Sektion wir uns befinden, repositioniert den inneliegenden Block mit der Klasse "hintergrundbild"
    if (gegenwaertigeSektion == 1) {
      $('#one .dhsv_vc_single_image').css('top', bewegungAktiv)
    } else {
      $('#one .dhsv_vc_single_image').css('top', bewegungProaktiv)
    }
    if (gegenwaertigeSektion == 2) {
      $('#two .dhsv_vc_single_image').css('top', bewegungAktiv)
    } else {
      $('#two .dhsv_vc_single_image').css('top', bewegungProaktiv)
    }
    if (gegenwaertigeSektion == 3) {
      $('#three .dhsv_vc_single_image').css('top', bewegungAktiv)
    } else {
      $('#three .dhsv_vc_single_image').css('top', bewegungProaktiv)
    }
  }
  */

  /*
  var parallaxBox = $(document).find('.parallax_box')

  var parallax = (function() {
    for (var i = 0; i < parallaxBox.length; i++) {
      var BodyScroll = document.body.scrollTop

      var ThisParallaxBox = parallaxBox[i]
      var ParentParralax = ThisParallaxBox.parent()
      var Abstand = ParentParralax.offset().top
      var ParallaxScroll = Math.round(
        (BodyScroll - Abstand) /
          ThisParallaxBox.height() *
          (ThisParallaxBox.height() - ThisParallaxBox.parent().height())
      )
      ThisParallaxBox.css({
        transform: 'translate3d(0px, ' + ParallaxScroll + 'px, 0px)',
      })
    }
  })(function loop() {
    requestAnimationFrame(loop)
    parallax()
  })
  */
}
