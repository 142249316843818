import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_externe_page_teaser()
})

function dhsv_vc_externe_page_teaser() {
  $('.dhsv_vc_externe_page_teaser').each(function() {})
  $('.dhsv_vc_externe_page_teaser').hover(
    function() {
      $(this)
        .find('a')
        .slideDown()
    },
    function() {
      $(this)
        .find('a')
        .slideUp()
    }
  )
}
