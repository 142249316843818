import 'slick-carousel'
import ScrollOut from 'scroll-out'
import Rellax from 'rellax'
//import * as animationData from './animationData'
//import lottie from 'lottie-web'

const modalInit = (modal, openBtn) => {
  let scrollTop = 0
  $(openBtn).click(function() {
    let clicks = $(this).data('clicks')
    if (!clicks) {
      // odd clicks
      scrollTop = $(window).scrollTop()
      $(modal).fadeIn(300)
      $('body')
        .addClass('js-fixed-modal')
        .css('top', -scrollTop)
      $(openBtn).addClass('js-opened')
      $('.banner').addClass('js-opened')
    } else {
      // even clicks
      $(modal).fadeOut(300)
      $('body')
        .removeClass('js-fixed-modal')
        .css('top', '')
      $('html, body').animate(
        {
          scrollTop: scrollTop,
        },
        0
      )
      $(openBtn).removeClass('js-opened')
      $('.banner').removeClass('js-opened')
    }
    $(this).data('clicks', !clicks)
  })
}

const stickyHeader = () => {
  const top = $('.banner').outerHeight() - 50
  $(window).scroll(function() {
    let y = $(this).scrollTop()
    if (y >= top) {
      $('.banner').addClass('fixed')
      $('.js-scroll-section').addClass('active')
    } else {
      $('.banner').removeClass('fixed')
      $('.js-scroll-section').removeClass('active')
    }
  })
}
const resizeFullWidthRow = () => {
  $('[data-vc-full-width="true"]').each(function() {
    $(this).css({
      width: document.body.clientWidth,
      marginLeft: -document.body.clientWidth / 2,
    })
    if (!$(this).attr('data-vc-stretch-content')) {
      let padding =
        (document.body.clientWidth - $('.container.wrap').outerWidth()) / 2
      $(this).css({
        'padding-left': padding,
        'padding-right': padding,
      })
    }
  })
}
const formsHandler = () => {
  $('input, textarea', $('.gfield', this)).each(function() {
    $(this).attr('placeholder', '')
    if (
      !$(this)
        .closest('.gfield')
        .find('.gfield_label')
        .hasClass('active')
    ) {
      if ($(this).val()) {
        $(this)
          .closest('.gfield')
          .find('.gfield_label')
          .addClass('active')
      }
      $(this).on('focusin', function() {
        $(this)
          .closest('.gfield')
          .find('.gfield_label')
          .addClass('active')
      })

      $(this).on('focusout', function() {
        if (!$(this).val()) {
          $(this)
            .closest('.gfield')
            .find('.gfield_label')
            .removeClass('active')
        }
      })
    }
  })
  $('textarea', '.gfield').each(function() {
    $(this)
      .closest('.gfield')
      .find('.gfield_label')
      .addClass('textarea-label')
  })
}
const homeSlider = () => {
  //$('.js-home-slider').css('display', 'flex')
  /*
  $('.js-home-slider')
    .slick({
      arrows: false,
      fade: true,
      cssEase: 'linear',
      autoplay: true,
      autoplaySpeed: 0,
      speed: 500,
      //speed: 2000,
    })
    .on('beforeChange', function() {
      var first_element = $('.slick-active').attr('data-slick-index')
      if (first_element == 0) {
        // eslint-disable-next-line no-console
        //console.log(first_element)
        $('.js-home-slider').slick('setOption', 'autoplaySpeed', 800)
        $('.js-home-slider').slick('setOption', 'speed', 800)
      } else {
        $('.js-home-slider').slick('setOption', 'autoplaySpeed', 0)
        $('.js-home-slider').slick('setOption', 'speed', 500)
        // eslint-disable-next-line no-console
        //console.log('Empty')
      }
    })
*/
  $('.js-home-slider')
  $('.js-home-slider')
    .css('display', 'flex')
    .slick({
      arrows: false,
      fade: true,
      cssEase: 'linear',
      autoplay: true,
      autoplaySpeed: 0,
      speed: 500,
      //speed: 2000,
    })
    .on('afterChange', function() {
      var content_text_top_right = $(
        '.slick-active .home-slider__top .highlight'
      ).html()
      var content_text_bottom_left = $(
        '.slick-active .home-slider__top .bounce'
      ).html()
      var dataId = $('.slick-active').attr('data-slick-index')
      //eslint-disable-next-line no-console
      //console.log(dataId)

      if (dataId <= 1) {
        $('.js-home-slider').slick('setOption', 'autoplaySpeed', 800)
      } else {
        // eslint-disable-next-line no-console
        //console.log(content + content.length)
        if (
          content_text_top_right.length !== 40 ||
          content_text_bottom_left.length !== 40
        ) {
          // eslint-disable-next-line no-console
          //console.log('Not Empty')
          // eslint-disable-next-line no-console
          $('.js-home-slider').slick('setOption', 'autoplaySpeed', 800)
          $('.js-home-slider').slick('setOption', 'speed', 800)
        } else {
          $('.js-home-slider').slick('setOption', 'autoplaySpeed', 0)
          $('.js-home-slider').slick('setOption', 'speed', 500)
          // eslint-disable-next-line no-console
          //console.log('Empty')
        }
      }
      // eslint-disable-next-line no-console
      //console.log(dataId)
    })
}

/*
const homeSlider = () => {
  setTimeout(() => {
    $('.js-home-slider')
      .slick({
        arrows: false,
        fade: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 0,
        speed: 300,
        //speed: 2000,
      })
      .on('afterChange', function() {
        var dataId = $('.slick-active').attr('data-slick-index')
        var content = $('.slick-active .home-slider__top .highlight').html()
        if (dataId == '1') {
          // eslint-disable-next-line no-console
          console.log('JA 1')
          //var content = $('.slick-active .home-slider__top .highlight').html()
          // eslint-disable-next-line no-console
          console.log(content)
          $('.js-home-slider').slick('setOption', 'autoplaySpeed', 1000)
        } else {
          $('.js-home-slider').slick('setOption', 'autoplaySpeed', 0)
        }
        // eslint-disable-next-line no-console
        console.log(dataId)
      })
  }, 3300)
}
*/
const menuItemImage = () => {
  $('.js-nav-primary .js-item-image .nav-link').hover(
    function() {
      const itemId = $(this).data('id')
      $('.js-menu-bg')
        .find(`.js-bg-item[data-id="${itemId}"]`)
        .addClass('active')
    },
    function() {
      $('.js-bg-item').removeClass('active')
    }
  )
}
const nextSectionScrollHandler = () => {
  const heroHeight = $('.js-home-hero').outerHeight()
  $('.next-section').on('click', function() {
    $('.js-scroll-section').addClass('active')
    let stickyMenuHeight = 85
    const currentBreakpoint = window.bootstrapToolkit.current()
    if (currentBreakpoint === 'xs') {
      stickyMenuHeight = 65
    }
    $('html, body').animate(
      {
        scrollTop: heroHeight - stickyMenuHeight,
      },
      500
    )
  })
}

const rellax = new Rellax('.rellax', {
  center: true,
  wrapper: null,
  round: true,
  vertical: true,
  horizontal: false,
  breakpoints: [576, 768, 1201],
})

/*
function startAnimation() {
  //const divContainer = $('.dhsv_clouds')[0]
  $('.dhsv_lottieanim').each(function() {
    if ($(this)[0]) {
      const params = {
        container: $(this)[0],
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData.default,
      }

      let animation = lottie.loadAnimation(params)
      animation.setSpeed(0.5)
    }
  })
}
*/

export default {
  init() {
    // JavaScript to be fired on all pages
    modalInit('.js-menu-modal', '.js-menu-btn')
    homeSlider()
    stickyHeader()
    resizeFullWidthRow()
    formsHandler()
    menuItemImage()
    nextSectionScrollHandler()
    //startAnimation()

    $(window).on('resize', function() {
      resizeFullWidthRow()
    })

    $(document).bind('gform_post_render', function() {
      formsHandler()
    })

    ScrollOut({
      targets: '.scrollOut',
      threshold: 0.5,
      once: true,
    })

    rellax
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
